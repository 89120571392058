// import anime from 'animejs/lib/anime.min';
// import { TweenMax } from 'gsap';
import './title-lettering.scss';
// import charming from 'charming';
const charming = require('charming');

const element = document.querySelectorAll('[data-charming]');

element.forEach((el) => {
  charming(el, {
    splitRegex: /(\s+)/,
    classPrefix: 'word',
  });
  const words = el.querySelectorAll('[class*="word"]');
  words.forEach((word) => {
    // console.log(el);
    charming(word);
    const chars = word.querySelectorAll('span');
    chars.forEach((char) => {
      char.setAttribute('data-animation-title', '');
      if (char.innerHTML === ' ') {
        char.innerHTML = '&nbsp;';// eslint-disable-line
      }
    });
  });
});
