import './background-shapes.scss';
import anime from 'animejs';
import scrollMonitor from 'scrollmonitor';

const shape = document.querySelector('.morph');
if (shape) {
  {
    const DOM = {};
    DOM.svg = document.querySelector('[data-shape]');
    DOM.shapeEl = DOM.svg.querySelector('polygon');
    DOM.contentElems = Array.from(document.querySelectorAll('[data-background-shapes]'));
    DOM.footer = document.querySelector('[data-footer]');
    const contentElemsTotal = DOM.contentElems.length;

    const shapes = [
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 0,
        scaleY: 0,
        rotate: -25,
        tx: 0,
        ty: 0,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          // points: {
          //   duration: 500,
          //   easing: 'easeOutExpo'
          // },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 0.7,
        scaleY: 0.7,
        rotate: 0,
        tx: 0,
        ty: 100,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          points: {
            duration: 500,
            easing: 'easeOutQuad',
          },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 0.85,
        scaleY: 0.85,
        rotate: 25,
        tx: 0,
        ty: 0,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          points: {
            duration: 500,
            easing: 'easeOutQuad',
          },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 1,
        scaleY: 1,
        rotate: 45,
        tx: 0,
        ty: 100,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          points: {
            duration: 500,
            easing: 'easeOutQuad',
          },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 0.85,
        scaleY: 0.85,
        rotate: 90,
        tx: 0,
        ty: 50,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          points: {
            duration: 500,
            easing: 'easeOutQuad',
          },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 0.7,
        scaleY: 0.7,
        rotate: 135,
        tx: 0,
        ty: 0,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          points: {
            duration: 500,
            easing: 'easeOutQuad',
          },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
      {
        points: '983.4,101.6 983.4,668.4 416.6,668.4 416.6,101.9 416.6,101.9 416.6,101.9',
        scaleX: 0.85,
        scaleY: 0.85,
        rotate: 180,
        tx: 0,
        ty: 0,
        fill: {
          color: 'none',
          duration: 500,
          easing: 'linear',
        },
        animation: {
          points: {
            duration: 500,
            easing: 'easeOutQuad',
          },
          svg: {
            duration: 850,
            easing: 'easeOutCubic',
          },
        },
      },
    ];
    let step;

    const initShapeEl = function () {
      anime.remove(DOM.svg);

      anime({
        targets: DOM.svg,
        duration: 1,
        easing: 'linear',
        scaleX: shapes[0].scaleX,
        scaleY: shapes[0].scaleY,
        translateX: `${shapes[0].tx}px`,
        translateY: `${shapes[0].ty}px`,
        rotate: `${shapes[0].rotate}deg`,
      });
    };

    const createScrollWatchers = function () {
      DOM.contentElems.forEach((el, pos) => {
        const scrollElemToWatch = pos ? DOM.contentElems[pos] : DOM.footer;
        pos = pos || contentElemsTotal; //eslint-disable-line
        const watcher = scrollMonitor.create(scrollElemToWatch, -350);
        // console.log(scrollElemToWatch,watcher, 'watcher');

        watcher.enterViewport(() => {
          step = pos;
          anime.remove(DOM.shapeEl);
          anime({
            targets: DOM.shapeEl,
            duration: shapes[pos].animation.points.duration,
            easing: shapes[pos].animation.points.easing,
            elasticity: shapes[pos].animation.points.elasticity || 0,
            points: shapes[pos].points,
            fill: {
              value: shapes[pos].fill.color,
              duration: shapes[pos].fill.duration,
              easing: shapes[pos].fill.easing,
            },
          });

          anime.remove(DOM.svg);
          anime({
            targets: DOM.svg,
            duration: shapes[pos].animation.svg.duration,
            easing: shapes[pos].animation.svg.easing,
            elasticity: shapes[pos].animation.svg.elasticity || 0,
            scaleX: shapes[pos].scaleX,
            scaleY: shapes[pos].scaleY,
            translateX: `${shapes[pos].tx}px`,
            translateY: `${shapes[pos].ty}px`,
            rotate: `${shapes[pos].rotate}deg`,
          });
        });

        watcher.exitViewport(() => {
          const idx = !watcher.isAboveViewport ? pos - 1 : pos + 1;

          if (idx) {
            step = idx;
            anime.remove(DOM.shapeEl);
            anime({
              targets: DOM.shapeEl,
              duration: shapes[idx].animation.points.duration,
              easing: shapes[idx].animation.points.easing,
              elasticity: shapes[idx].animation.points.elasticity || 0,
              points: shapes[idx].points,
              fill: {
                value: shapes[idx].fill.color,
                duration: shapes[idx].fill.duration,
                easing: shapes[idx].fill.easing,
              },
            });

            anime.remove(DOM.svg);
            anime({
              targets: DOM.svg,
              duration: shapes[idx].animation.svg.duration,
              easing: shapes[idx].animation.svg.easing,
              elasticity: shapes[idx].animation.svg.elasticity || 0,
              scaleX: shapes[idx].scaleX,
              scaleY: shapes[idx].scaleY,
              translateX: `${shapes[idx].tx}px`,
              translateY: `${shapes[idx].ty}px`,
              rotate: `${shapes[idx].rotate}deg`,
            });
          }
        });
      });
    };

    const init = function () {
      initShapeEl();
      createScrollWatchers();
    };
    init();
  }
}
