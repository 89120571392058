// import 'nodelist-foreach-polyfill';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import './modal-video.scss';

document.addEventListener('DOMContentLoaded', () => {
  const modalEl = document.createElement('div');
  const modalWrapperEl = document.createElement('div');
  const modalCloseEl = document.createElement('button');
  const modalContentEl = document.createElement('div');
  modalEl.classList.add('modal');
  modalEl.setAttribute('aria-modal', 'true');
  modalWrapperEl.classList.add('modal__wrapper');
  modalCloseEl.classList.add('modal__close');
  modalContentEl.classList.add('modal__content');
  modalCloseEl.innerHTML = '✕';
  modalWrapperEl.appendChild(modalContentEl);
  modalEl.appendChild(modalWrapperEl);
  modalEl.appendChild(modalCloseEl);
  document.body.appendChild(modalEl);
  document.querySelectorAll('[data-modal-wrapper]').forEach((wrapper) => {
    const toggle = wrapper.querySelector('[data-modal-toggle]');
    const content = wrapper.querySelector('[data-modal-content]');
    toggle.addEventListener('click', () => {
      modalContentEl.innerHTML = content.innerHTML;
      modalEl.classList.add('modal_opened');
      disableBodyScroll(modalEl);
    });
    modalCloseEl.addEventListener('click', () => {
      modalEl.classList.remove('modal_opened');
      enableBodyScroll(modalEl);
      setTimeout(() => {
        modalContentEl.innerHTML = '';
      }, 300);
    });
  });
});
