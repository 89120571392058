import './header.scss';
import './hamburger.scss';
import './menu.scss';

import scrollBarWidth from 'scrollbarwidth';

document.addEventListener('DOMContentLoaded', () => {
  const asideMenu = document.querySelector('[data-role="aside-menu"]');
  const buttonMenu = document.querySelector('[data-role="menu-toggle"]');
  // const itemWithSubLinks = document.querySelector('[data-role="nav"]');
  buttonMenu.addEventListener('click', () => {
    const scrollbar = scrollBarWidth();
    asideMenu.classList.toggle('opened');
    if (asideMenu.classList.contains('opened')) {
      document.documentElement.classList.add('has-modal');
      document.documentElement.style.setProperty('--scrollbar-w', `${scrollbar}px`);
    } else {
      document.documentElement.classList.remove('has-modal');
      document.documentElement.style.setProperty('--scrollbar-w', '0px');
    }
  });
});
