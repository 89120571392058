export const DOCUMENT_ELEMENT = document.documentElement;
export const ROOT_SELECTOR = '[data-role="root"]';
export const ROOT_ELEMENT = document.querySelector(ROOT_SELECTOR);
export const SCROLL_ELEMENT = document.body;
export const ROOT_CLASS = 'application';
export const SCROLLBAR_PADDING_SELECTORS = ['body', 'header'];

export const KEY_CODES = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  ESC: 27,
  SPACE: 32,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  LEFT_WINDOW_KEY: 91,
};

export const FORM_ELEMENTS = {
  INPUT_BLOCK: '[data-role="input-block"]',
  INPUT_BLOCK__INPUT: '[data-role*="input-block__input"]',
  INPUT_BLOCK__LABEL: '[data-role*="input-block__label"]',
  INPUT_BLOCK__ERROR: '[data-role*="input-block__error"]',
};

export const FORM_CLASSES = {
  FOCUSED: 'input-block_focused',
  INVALID: 'input-block_invalid',
};

export const EVENTS = {
  lazyImages_update: Symbol('LAZY-IMAGES:UPDATE'),
  lazyImages_loadForce: Symbol('LAZY-IMAGES:LOAD-FORCE'),
  lazyImages_imageLoaded: Symbol('LAZY-IMAGES:IMAGE-LOADED'),
};
