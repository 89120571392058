import GoogleMapsLoader from 'google-maps';
import mapStyles from './styles.json';
import './googleMaps.scss';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-role*="google-maps-container"]').forEach((el) => {
    const {
      key, options, coords, markerImage,
    } = el.dataset;
    GoogleMapsLoader.KEY = key;
    const coordsArr = JSON.parse(coords);
    const [centerElement] = coordsArr;
    GoogleMapsLoader.load((google) => {
      const center = new google.maps.LatLng(centerElement.lat, centerElement.lng);
      const map = new google.maps.Map(el, {
        center,
        styles: mapStyles,
        ...JSON.parse(options),
      });
      coordsArr.forEach((coordsElement) => {
        const markerCenter = new google.maps.LatLng(coordsElement);
        const marker = new google.maps.Marker({
          position: markerCenter,
          icon: markerImage,
          optimized: false,
          origin: new google.maps.Point(16, 16),
          draggable: false,
          animation: google.maps.Animation.DROP,
        });
        marker.addListener('click', () => {
          marker.setAnimation(google.maps.Animation.BOUNCE);
          setTimeout(() => {
            marker.setAnimation(null);
          }, 750);
        });
        marker.setMap(map);
      });
    });
  });
});
