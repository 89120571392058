import './form.scss';
import isMobile from 'ismobilejs';


// render Select
function renderSelects() {
  if (isMobile.any) return;
  const prefix = '_select';
  const selects = document.querySelectorAll('[data-custom-select]');
  // console.log(selects);
  // Html set
  for (let i = 0; i < selects.length; i++) { //eslint-disable-line
    const isCustom = selects[i].nextElementSibling;
    if (!isCustom) {
      selects[i].classList.add('hide-select');
      const selectClassByName = selects[i].getAttribute('name');
      const takePlaceholder = selects[i].getAttribute('data-placeholder');
      let placeholder = '';
      if (takePlaceholder) placeholder = takePlaceholder;
      selects[i].insertAdjacentHTML('afterend', '\n <div class="select_common ' + selectClassByName + prefix + '">\n <div class="placeholder-select">' + placeholder + '</div>\n <ul class="select__items"></ul>\n </div>');//eslint-disable-line
      const checkTextPlaceholder = selects[i].nextElementSibling.querySelector('.placeholder-select');
      if (!checkTextPlaceholder.textContent) {
        for (let o = 0; o < selects[i].children.length; o++ ) { //eslint-disable-line
          if (selects[i].children[o].selected) {
            checkTextPlaceholder.insertAdjacentHTML('afterbegin', selects[i].children[o].textContent);
          }
        }
      }
      // Add options
      const selectOptions = selects[i].querySelectorAll('option');
      const checkOptionText = selects[i].nextElementSibling.querySelector('.select__items');
      if (!checkOptionText.textContent) {
        for (let j = 0; j < selectOptions.length; j++) { //eslint-disable-line
          checkOptionText.insertAdjacentHTML('beforeend', '\n  <li>' + selectOptions[j].textContent + '</li>'); //eslint-disable-line
        }
      }
    }
  }

  // Click on document and remove class .active from customSelect
  document.addEventListener('click', () => {
    for (let i = 0; i < selects.length; i++) { //eslint-disable-line
      const getSelectName = selects[i].getAttribute('name');
      const customSelect = document.querySelectorAll('.'+getSelectName + prefix); //eslint-disable-line
      for (let j = 0; j < customSelect.length; j++) { //eslint-disable-line
        customSelect[j].classList.remove('select_active');
      }
    }
  });
  /* Add events */
  function loop(i) {
    if (!selects[i].nextElementSibling.getAttribute('data-event')) {
      // Set attribute added
      selects[i].nextElementSibling.setAttribute('data-event', true);
      // Click on placeholder
      selects[i].nextElementSibling.querySelector('.placeholder-select').addEventListener('click', (e) => {
        const takeCurrentEl = e.currentTarget.parentNode;
        for (let o = 0; o < selects.length; o++) { //eslint-disable-line
          if (takeCurrentEl !== selects[o].nextElementSibling) {
            selects[o].nextElementSibling.classList.remove('select_active');
          }
        }
        e.currentTarget.parentNode.classList.toggle('select_active');
      });
      selects[i].nextElementSibling.addEventListener('click', (e) => { e.stopPropagation(); });
      // Click on options
      const addEventToLi = selects[i].nextElementSibling.querySelector('.select__items');
      const itemsLi = addEventToLi.querySelectorAll('li');
      function loopInner(l) { //eslint-disable-line
        itemsLi[l].addEventListener('click', (e) => {
          // Change text in placeholder
          const optionVal = e.currentTarget.textContent;
          selects[i].nextElementSibling.querySelector('.placeholder-select').textContent = optionVal;
          selects[i].nextElementSibling.classList.remove('select_active');
          // Add value in native select
          const optionsNative = selects[i].querySelectorAll('option');
          for (let j = 0; j < optionsNative.length; j++) { //eslint-disable-line
            optionsNative[j].removeAttribute('selected');
          }
          optionsNative[l].setAttribute('selected', 'selected');
        });
      }
      for (let l = 0; l < itemsLi.length; l++) { //eslint-disable-line
        loopInner(l);
      }
    }
  }
  for (let i = 0; i < selects.length; i++) { //eslint-disable-line
    loop(i);
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const dateInputSupported = isDateInputSupported(); //eslint-disable-line
  document.querySelectorAll('[data-input-wrapper]').forEach((wrapper) => {
    const input = wrapper.querySelector('[data-input]');
    const label = wrapper.querySelector('[data-label]');
    renderSelects();
    // input.addEventListener('invalid', () => {
    //   input.form.classList.add('errors-vizualized');
    // });
    const changeEvents = ['keydown', 'cut', 'paste', 'focusin', 'focusout', 'change'];
    if (input.type === 'date') {
      label.classList.add('input__label_up');//eslint-disable-line
    }
    function moveLabel() {
      const isLabelUp = document.activeElement === input || input.value.trim() !== ''
        || (dateInputSupported && input.type === 'date');
      label.classList.toggle('input__label_up', isLabelUp);//eslint-disable-line
    }
    changeEvents.forEach((eventKey) => {
      input.addEventListener(eventKey, moveLabel);//eslint-disable-line
    });
    // moveLabel();


    document.querySelectorAll('[data-should-match]').forEach((input) => {//eslint-disable-line
      const shouldMatch = input.form[input.dataset.shouldMatch];
      update();//eslint-disable-line
      shouldMatch.addEventListener('input', update);//eslint-disable-line
      input.addEventListener('input', update);//eslint-disable-line

      function update() {
        if (input.value === shouldMatch.value) {
          input.setCustomValidity('');
        } else {
          input.setCustomValidity(input.dataset.shouldMatchMessage);
        }
      }
    });
  });
  function isDateInputSupported() {
    const input = document.createElement('input');
    const value = 'a';
    input.setAttribute('type', 'date');
    input.setAttribute('value', value);
    return input.value !== value;
  }
  // const form = document.querySelector('form');
  // const button = document.querySelector('.button_form');
  // const buttonText = document.querySelector('.button_form span');
  // const text = 'Sent';
  // const tickMark = `<svg class="icon-done" width="32" height="19"
  //   viewBox="0 0 58 45" xmlns="http://www.w3.org/2000/svg"><path fill="#333C4A" fill-rule="nonzero"
  //   d="M19.11 44.64L.27 25.81l5.66-5.66 13.18 13.18L52.07.38l5.65 5.65"/></svg>`;

  // function handleForm(event) {
  //   event.preventDefault();
  //   // console.log(event);
  //   const data = new FormData(event.target);
  //   const obj = {};
  //   data.forEach((value, key) => {
  //     obj[key] = value;
  //   });
  //   let flag = false;

  //   if (flag) {
  //     buttonText.innerHTML = text;
  //   } else {
  //     buttonText.innerHTML = tickMark;
  //   }

  //   flag = !flag;
  //   button.classList.toggle('button_form-circle');
  //   form.reset();

  //   setTimeout(() => {
  //     buttonText.innerHTML = text;
  //     button.classList.toggle('button_form-circle');
  //   }, 1500);

  //   const data2 = JSON.stringify(obj); // eslint-disable-line
  //   // console.log(data2);
  // }
  // if (form) {
  //   form.addEventListener('submit', handleForm);
  // }
});
