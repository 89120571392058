import createEvent from '../../scripts/utils/create-event';
import { DOCUMENT_ELEMENT } from '../../scripts/constans';
import transitionend from '../../scripts/utils/transitionend';

import ParticlesPreloader from '../ParticlesPreloader/ParticlesPreloader';
import './preloader.scss';

const defaults = {
  element: null,
  activeClass: 'has-preloader',
  hiddenClass: 'is-hidden',
  startTimeout: 500,
};

export default class Preloader {
  constructor(props) {
    const options = Object.assign({}, defaults, props);
    this.element = options.element;
    if (!this.element) return;

    this.activeClass = options.activeClass;
    this.startClass = options.startClass;
    this.hiddenClass = options.hiddenClass;
    this.startTimeout = options.startTimeout;
    this.key = 'ew-preloader';

    this.particlesPreloader = null;
  }

  load() {
    console.log('Loading'); // eslint-disable-line no-console
    if (sessionStorage.getItem(this.key)) {
      this.removePreloader(0);
    } else {
      this.element.classList.add('is-dark');
      sessionStorage.setItem(this.key, 'true');
      this.particlesPreloader = new ParticlesPreloader({
        element: document.querySelector('[data-role="particles-preloader"]'),
      });
      setTimeout(() => {
        this.particlesPreloader.play(this.removePreloader.bind(this));
      }, 1000);
    }
  }

  removePreloader(timeout = this.startTimeout) {
    DOCUMENT_ELEMENT.classList.remove(this.activeClass);
    DOCUMENT_ELEMENT.dispatchEvent(createEvent('preloaderEnd'));
    setTimeout(() => {
      transitionend(this.element, 'transitionend', () => {
        console.log('Loading finished. Enjoy!'); // eslint-disable-line no-console
        this.element.remove();
      });
    }, timeout);
  }
}
