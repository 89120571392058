import * as THREE from 'three';
import { TimelineMax, TweenMax, Expo } from 'gsap/TweenMax';
import _debounce from 'lodash/debounce';
import noise from '../../scripts/utils/perlinNoise';
import vertex from './vertex.glsl';
import fragment from './fragment.glsl';

const defaults = {
  element: null,
};

export default class ParticlesPreloader {
  constructor(props) {
    const options = Object.assign({}, defaults, props);
    this.element = options.element;
    if (!this.element) return;

    this.dpi = window.devicePixelRatio;
    this.vw = window.innerWidth;
    this.vh = window.innerHeight;
    this.aspect = 1;

    // THEE init
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0x101010);
    this.camera = new THREE.PerspectiveCamera(
      70,
      this.vw / this.vh,
      0.001, 100,
    );
    this.camera.position.z = 1;

    this.scene.add(this.camera);

    this.renderer = new THREE.WebGLRenderer();
    this.geometry = new THREE.BufferGeometry();
    this.material = new THREE.ShaderMaterial({
      uniforms: {
        progress: { type: 'f', value: 0 },
      },
      vertexShader: vertex,
      fragmentShader: fragment,
      transparent: true,
    });
    this.particles = null;
    this.bindedRender = this.render.bind(this);

    this.init();
  }

  get imageData() {
    return new Promise((resolve) => {
      const { src } = this.element.dataset;
      const image = new Image();
      image.addEventListener('load', () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const { width, height } = image;
        this.aspect = width / height;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0);
        resolve(ctx.getImageData(0, 0, width, height));
      });
      image.src = src;
    });
  }

  async drawImage() {
    const imageData = await this.imageData;
    const positions = [];
    const noisePos = [];
    const colors = [];
    const { data, width, height } = imageData;
    let i = 0;
    let j = 0;
    for (let x = 0; x < width; x += 1) {
      i += 0.01;
      for (let y = 0; y < height; y += 1) {
        j += 0.01;
        const coord = (x * 4 + y * 4 * width);
        const point = {
          r: data[coord] / 255,
          g: data[coord + 1] / 255,
          b: data[coord + 2] / 255,
          a: data[coord + 3] / 255,
        };
        if (point.a > 0) {
          positions.push((x - width / 2) / width, (-y + height / 2) / height, 0);
          noisePos.push(
            2 - noise(Math.sin(i), Math.cos(j), i + j / 2) * 4 * (2 - Math.random() * 2),
            4 - noise(Math.sin(j), Math.cos(i), j + i / 2) * 8 * (1 - Math.random() * 2),
            2 - noise(Math.sin(j), Math.cos(i), j + i / 2) * 4 * (1 - Math.random() * 2),
          );
          colors.push(point.r, point.g, point.b, point.a);
        }
      }
    }
    this.geometry.addAttribute('position', new THREE.Float32BufferAttribute(positions, 3));
    this.geometry.addAttribute('noise', new THREE.Float32BufferAttribute(noisePos, 3));
    this.geometry.addAttribute('color', new THREE.Float32BufferAttribute(colors, 4));
    this.particles = new THREE.Points(this.geometry, this.material);
    this.scene.add(this.particles);
    requestAnimationFrame(() => {
      this.resize();
      window.addEventListener('resize', _debounce(() => this.resize(), 100));
    });
  }

  play(callback) {
    const tl = new TimelineMax({
      onComplete: () => {
        TweenMax.to(this.material.uniforms.progress, 2, {
          value: 0,
          ease: Expo.easeOut,
        }, 1);
        setTimeout(callback, 700);
      },
    });
    tl
      .to(this.material.uniforms.progress, 5, {
        value: 1,
        ease: Expo.easeOut,
      });
    // .staggerFromTo()
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }

  resize() {
    this.vw = window.innerWidth;
    this.vh = window.innerHeight;
    this.renderer.setSize(this.vw, this.vh);
    this.camera.aspect = this.vw / this.vh;

    // calculate scene
    const dist = this.camera.position.z - this.particles.position.z;
    const height = 1;
    this.camera.fov = 2 * (180 / Math.PI) * Math.atan(height / (2 * dist));

    this.particles.scale.x = this.aspect * 0.2;
    this.particles.scale.y = 0.2;

    this.camera.updateProjectionMatrix();
  }

  init() {
    this.renderer.setPixelRatio(this.dpi);
    this.renderer.setSize(this.vw, this.vh);
    this.renderer.setClearColor(0x161616);
    this.element.appendChild(this.renderer.domElement);
    TweenMax.ticker.addEventListener('tick', this.bindedRender);
    this.drawImage();
  }
}
