import './tabs.scss';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-tab-wrapper]').forEach((wrapper) => {
    const tabLink = wrapper.querySelectorAll('[data-tab]');
    // const tabContent = wrapper.querySelector('[data-tab-content]');
    // console.log(tabLink);
    const clear = () => {
      tabLink.forEach((e) => {
        e.classList.remove('active');
        const id = e.getAttribute('data-tab');
        document.getElementById(id).classList.remove('active');
        document.getElementById(id).classList.remove('display-active');
      });
    };
    const change = (e) => {
      clear();
      e.currentTarget.classList.add('active');
      const id = e.currentTarget.getAttribute('data-tab');
      document.getElementById(id).classList.add('display-active');
      document.getElementById(id).classList.add('active');
    };
    tabLink.forEach((e) => {
      e.addEventListener('click', change, false);
    });
  });
});
