import 'swiper/dist/css/swiper.css';
import './slider.scss';
import './cover-slider.scss';
import { TimelineMax, TweenMax } from 'gsap';
import Swiper from 'swiper/dist/js/swiper';
import { DOCUMENT_ELEMENT } from '../../scripts/constans';

// cover slider
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-slider-cover-wrapper]').forEach((wrapper) => {
    const container = wrapper.querySelector('[data-slider-container]');
    // const selector = '[data-role="cover-slider-stagger"]';
    const selectorImage = '[data-role="cover-slider-image"]';
    const selectorFadeUp = '[data-slider-animation-fade-up]';

    const tl = new TimelineMax();
    // const tl2 = new TimelineMax();
    const swiper = new Swiper(container, {// eslint-disable-line
      spaceBetween: 0,
      speed: 1200,
      grabCursor: false,
      simulateTouch: false,
      autoplay: {
        delay: 5000,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      slidesPerView: 1,
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet(index, className) {
          return `<span class=${className}> 0${index + 1}</span>`;
        },
      },
      on: {
        slideChangeTransitionStart() {
          TweenMax.to(`.swiper-slide-prev ${selectorImage}`, 1, {
            ease: Expo.easeInOut,// eslint-disable-line
            startAt: { x: '0%', scale: 1 },
            x: '-10%',
            scale: 1.2,
          });
          TweenMax.to(`.swiper-slide-active ${selectorImage}`, 1, {
            ease: Expo.easeInOut,// eslint-disable-line
            startAt: { x: '10%', scale: 1.2 },
            x: '0%',
            scale: 1,
          });
          tl.staggerFromTo('.swiper-slide-active [data-animation-title]', 1,
            { opacity: 0, rotationY: 90 }, { opacity: 1, rotationY: 0 }, 0.035);
          TweenMax.staggerFromTo(selectorFadeUp, 0.5, { opacity: 0, y: 100 },
            { opacity: 1, y: 0, delay: 1 });
        },
      },
    });
    DOCUMENT_ELEMENT.addEventListener('preloaderEnd', () => {
      swiper.update();
    });
  });

  // service-slider
  document.querySelectorAll('[data-slider-service-wrapper]').forEach((wrapper) => {
    const container = wrapper.querySelector('[data-slider-container]');
    const swiper = new Swiper(container, {// eslint-disable-line
      // loop: true,
      effect: 'slide',
      speed: 600,
      spaceBetween: 32,
      centeredSlides: true,
      slideToClickedSlide: true,
      slidesPerView: 5,
      initialSlide: 3,
      autoplay: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 16,
        },
      },
    });
  });


  // small slider
  document.querySelectorAll('[data-slider-wrapper="small"]').forEach((wrapper) => {
    const container = wrapper.querySelector('[data-slider-container]');
    const swiper = new Swiper(container, {// eslint-disable-line
      // loop: true,
      effect: 'slide',
      speed: 1000,
      spaceBetween: 32,
      centeredSlides: true,
      slideToClickedSlide: true,
      slidesPerView: 3.9,
      initialSlide: 2,
      breakpoints: {
        1024: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 0,
        },
      },
    });
  });

  // text-slider
  document.querySelectorAll('[data-slider-wrapper="text"]').forEach((wrapper) => {
    const container = wrapper.querySelector('[data-slider-container]');
    const swiper = new Swiper(container, {// eslint-disable-line
      // loop: true,
      effect: 'slide',
      speed: 800,
      spaceBetween: 32,
      centeredSlides: true,
      slideToClickedSlide: true,
      slidesPerView: 5.5,
      initialSlide: 4,
      breakpoints: {
        1024: {
          slidesPerView: 3.9,
          spaceBetween: 24,
        },
        640: {
          slidesPerView: 1.5,
          spaceBetween: 0,
        },
      },
    });
  });


  // big-slider
  document.querySelectorAll('[data-slider-big-wrapper]').forEach((wrapper) => {
    const container = wrapper.querySelector('[data-slider-container]');
    const pagination = wrapper.querySelector('[data-slider-pagination]');
    // const navigation = wrapper.querySelector('[data-slider-navigation]');
    const swiper = new Swiper(container, {// eslint-disable-line
      // loop: true,
      effect: 'slide',
      speed: 1000,
      spaceBetween: 32,
      centeredSlides: true,
      // slideToClickedSlide: true,
      simulateTouch: false,
      slidesPerView: 1.5,
      initialSlide: 2,
      pagination: {
        el: pagination,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  });
});
