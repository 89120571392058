// import your components, fonts, js
import './styles/main.scss';

import './fonts/Prata-Regular.ttf';
import './fonts/Raleway-Regular.ttf';
import './fonts/Roboto-Medium.ttf';

// utils


import './components/header';
import './components/form';
import './components/tabs';
import './components/slider';
import './components/googleMaps';
import './components/modalVideo';
import './components/galleryCards';
import './components/titleLettering';
import './components/backgroundShapes';
import './components/scrollmagic';
import './hash-navigation';

import animatedScrollTo from 'animated-scroll-to';
import isMobile from 'ismobilejs';

// required for ie11 support by default, extract to separate file if you want
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';


// preloader
import Preloader from './components/preloader';

document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
  svg4everybody();

  const scrollToTop = document.querySelector('[data-scroll-to-top]');
  scrollToTop.addEventListener('click', () => {
    animatedScrollTo(0, { speed: 700 });
  });
  // adding first title letter to the back
  document.querySelectorAll('[data-title]').forEach((el) => {
    el.children[0].innerText = el.children[1].innerText.charAt(0); //eslint-disable-line
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const preloader = new Preloader({
    element: document.querySelector('[data-role="preloader"]'),
  });
  preloader.load();
  document.documentElement.classList.add(isMobile.any ? 'mobile' : 'desktop');
});
