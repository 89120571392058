import { TweenMax, TimelineMax } from 'gsap/TweenMax';
import Scrollmagic from 'scrollmagic';
// import MobileDetect from 'mobile-detect';

// section animation
function initSections() {
  TweenMax.set('[data-animation-header]', { opacity: 0, y: -30 });
  const tl = new TimelineMax();
  const tl0 = new TimelineMax();
  const controller = new Scrollmagic.Controller();
  tl0.staggerFromTo('[data-animation-header]', 0.5, { opacity: 0, y: -30 },
    { opacity: 1, y: 0, zIndex: 5, delay: 1.3 });// eslint-disable-line
  document.querySelectorAll('[data-cover-section]').forEach((wrapper) => {
    const tl2 = new TimelineMax();

    const selectorFadeLeft = wrapper.querySelectorAll('[data-animation-fade="left"]');
    const selectorTitle = wrapper.querySelectorAll('[data-animation-title]');
    const selectorCoverImage = wrapper.querySelectorAll('[data-animation-cover-image]');
    const selectorFadeUp = wrapper.querySelectorAll('[data-animation-fade-up]');

    TweenMax.set(wrapper, { opacity: 0 });
    TweenMax.set(selectorCoverImage, { opacity: 0, scale: 1.1 });
    TweenMax.set(selectorFadeUp, { opacity: 0, yPercent: 100 });
    TweenMax.set(selectorFadeLeft, { opacity: 0, xPercent: -100 });

    tl.to(wrapper, 0.1, { opacity: 1, delay: 0.7 });
    tl.staggerFromTo(selectorCoverImage, 1, { scale: 1.1, opacity: 0 },
      { ease: Circ.easeOut, scale: 1, opacity:1 }); // eslint-disable-line
    tl2.staggerFrom(selectorTitle, 0.5, { opacity: 0, x: 20, delay: 0.5 }, 0.035);
    tl.staggerTo(selectorFadeUp, 0.5, { opacity: 1, yPercent: 0 });
    tl0.staggerTo(selectorFadeLeft, 0.5, { opacity: 1, xPercent: 0 }, 0.035);
  });
  document.querySelectorAll('[data-subpage]').forEach((wrapper) => {
    const tl2 = new TimelineMax();

    const selectorFadeLeft = wrapper.querySelectorAll('[data-animation-fade="left"]');
    const selectorTitle = wrapper.querySelectorAll('[data-animation-title]');
    const selectorCoverImage = wrapper.querySelectorAll('[data-animation-cover-image]');
    const selectorFadeUp = wrapper.querySelectorAll('[data-animation-fade-up]');

    TweenMax.set(wrapper, { opacity: 0 });
    TweenMax.set(selectorCoverImage, { opacity: 0, scale: 1.1 });
    TweenMax.set(selectorFadeUp, { opacity: 0, yPercent: 100 });
    TweenMax.set(selectorFadeLeft, { opacity: 0, xPercent: -100 });

    tl.to(wrapper, 0.1, { opacity: 1, delay: 0.7 });
    tl.staggerFromTo(selectorCoverImage, 1, { scale: 1.1, opacity: 0 },
      { ease: Circ.easeOut, scale: 1, opacity:1 });// eslint-disable-line
    tl2.staggerFromTo(selectorTitle, 1,
      { opacity: 0, rotationY: 90 }, { opacity: 1, rotationY: 0, delay: 0.5 }, 0.035);
  });


  document.querySelectorAll('[data-role="section"]').forEach((wrapper) => {
    const tl2 = new TimelineMax();
    const tl3 = new TimelineMax();
    const tl4 = new TimelineMax();
    const tl5 = new TimelineMax();
    const tl6 = new TimelineMax();
    const tl7 = new TimelineMax();

    const selectorTitle = wrapper.querySelectorAll('[data-animation-title]');
    const selectorText = wrapper.querySelectorAll('[data-animation-text]');
    const selectorImage = wrapper.querySelectorAll('[data-animation-image]');
    const selectorImageSquare = wrapper.querySelectorAll('[data-animation-square]');
    const selectorFadeUp = wrapper.querySelectorAll('[data-animation-fade-up]');
    const selectorFadeIn = wrapper.querySelectorAll('[data-animation-fade-in]');
    const selectorFade = wrapper.querySelectorAll('[data-animation-fade]');
    const selectorTabCard = wrapper.querySelectorAll('[data-tab-content]');

    TweenMax.set(selectorImageSquare, { yPercent: -100 });
    TweenMax.set(selectorFadeUp, { opacity: 0, y: 100 });
    TweenMax.set(selectorFadeIn, { opacity: 0, scale: 1.1 });
    TweenMax.set(selectorFade, { opacity: 1 });
    TweenMax.set(selectorTitle, { opacity: 0, x: 20 });
    TweenMax.set(selectorTabCard, { opacity: 0 });

    const scene = new Scrollmagic.Scene({
      duration: wrapper.clientHeight,
      triggerElement: wrapper,
      triggerHook: 0.7,
      reverse: false,
    }).addTo(controller);
    scene.on('enter', () => {
      tl2.staggerTo(selectorTitle, 0.5, { opacity: 1, x: 0 }, 0.035);
      tl3.staggerFrom(selectorText, 0.75, { y: 500 }, 0.06);
      tl4.staggerTo(selectorImageSquare, 0.75, { yPercent: 0, opacity: 0 });
      tl5.staggerTo(selectorImage, 0.75, { yPercent: 100 });
      tl6.staggerTo(selectorFadeUp, 0.75, {
        opacity: 1, y: 0, delay: 0.5, clearProps: 'transform',
      }, 0.2);
      tl7.staggerTo(selectorFadeIn, 0.5, { opacity: 1, scale: 1, delay: 0.5 });
      tl7.staggerTo(selectorFade, 0.1, { opacity: 1 });

      selectorFade.forEach((el) => {
        tl7.set(el, { className: '+=loaded' });
      });

      tl3.delay(0.2);
      tl4.delay(0.05);
      tl5.delay(0.05);
      tl2.to(selectorTabCard, 0.5, { opacity: 1 });

      selectorTabCard.forEach((el) => {
        tl2.set(el, { className: '+=active' });
      });
    });
  });

  // bg parallax
  document.querySelectorAll('[data-bg-parallax]').forEach((el) => {
    // TweenMax.set(el, { y: -50 });
    const multiplier = 100;
    let top;
    // console.log(el);
    new Scrollmagic.Scene({
      duration: '100%',
      triggerElement: el,
      offset: -100,
    }).on('progress', (event) => {
      top = (event.progress * multiplier);
      TweenMax.to(el, 0.65, { y: top });
    }).addTo(controller);
  });
}
initSections();
