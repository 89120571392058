import animatedScrollTo from 'animated-scroll-to';

document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.header');
  function scrollPos() {
    return window.pageYOffset || document.documentElement.scrollTop;
  }
  let focusedElement;
  function moveTo(hash) {
    const id = hash.substr(1);
    const target = document.querySelector(`[data-id=${id}]`);
    if (target) {
      const headerOffset = header ? header.clientHeight : 0;
      const rect = target.getBoundingClientRect();
      animatedScrollTo(scrollPos() + rect.top - headerOffset);
      if (focusedElement) {
        focusedElement.classList.remove('focused');
      }
      target.classList.add('focused');
      focusedElement = target;
    }
  }
  document.querySelectorAll('a[href^="#"]').forEach(el => el.addEventListener('click', (e) => {
    e.stopPropagation();
    moveTo(el.getAttribute('href'));
  }));
  document.addEventListener('click', (e) => {
    if (focusedElement && !focusedElement.contains(e.target)) {
      focusedElement.classList.remove('focused');
      focusedElement = undefined;
    }
  });
  if (window.location.hash) {
    moveTo(window.location.hash);
  }
});
